import React from 'react'
import { useCustomI18NTranslatorHook } from '../../../utility/globalization'
import useLoader from '../useLoader/useLoader'
import WelcomeLoader from '../WelcomeLoader/WelcomeLoader'

const ActionModal = props => {
  const {
    messageText,
    visible,
    onCancelClick,
    onOkClick,
    cancelButtonText = 'Cancel',
    okButtonText = 'Ok',
    loading,
    onlyOkButton = false,
    options,
    showButton = true,
    customButton,
    customCloseButton, // Add customCloseButton prop
  } = props
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  const Loader = useLoader()

  const GetButton = () => {
    if (showButton) {
      if (customButton) {
        return customButton
      }

      return (
        <div className='modalFooter'>
          <div className='footerbtn-wrp'>
            {onlyOkButton ? null : (
              <button className='outerlineBtn mx-1' onClick={onCancelClick}>
                {translateTextI18N(cancelButtonText)}
              </button>
            )}
            <button className='cmnBtn mx-1' onClick={onOkClick}>
              {translateTextI18N(okButtonText)} {loading ? Loader : null}
            </button>
          </div>
        </div>
      )
    }

    return <WelcomeLoader />
  }

  return (
    <>
      <div className={`CmnModal-wrp ${visible ? 'open' : ''}`}>
        <div className='modalContent' style={{ position: 'relative' }}>
          {' '}
          {/* Add position: relative here */}
          {/* Add customCloseButton here */}
          {customCloseButton && (
            <div
              style={{
                position: 'absolute',
                top: '-5px',
                right: '5px',
              }}
            >
              {customCloseButton}
            </div>
          )}
          <div className='modalBody'>
            {/* Replace <p> with <div> to avoid invalid nesting */}
            <div style={{ color: 'black' }}>
              {options
                ? translateTextI18N(messageText, options)
                : translateTextI18N(messageText)}
            </div>
          </div>
          {GetButton()}
        </div>
      </div>
    </>
  )
}

export default ActionModal
